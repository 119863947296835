import React from 'react';
import styled , {css} from 'styled-components';

const StyledButton = styled.button`

  width:100%;
  padding:0;
  line-height:47px;
  font-weight:900;
  font-family:Roboto;
  border:3px solid transparent;
  font-size:24px;
  border-radius: 26px;
  transition:all .25s ease-in-out;
  box-sizing:border-box;
  
  &:hover{
    /*border: solid 3px #000000;
    background:transparent;*/
  }

  & a{color:inherit; text-decoration:none; display:inline-block; width:100%;}

  ${props => props.primary && css`
    background-color: #ffd900;
    background-image: linear-gradient(99deg, #ffee00 12%, #ffd900 74%);
    box-shadow: 0 7px 9px 0 rgba(203, 158, 0, 0.73);
    border:0; height:50px;
    `}

  ${props => props.secondary && css`
    background-color: #7a0000;
    box-shadow: 0 7px 17px 0 rgba(203, 0, 0, 0.73);
    background-image: linear-gradient(99deg, #b60a0a 12%, #7a0000 90%);
    `}

    ${props => props.knockedOut && css`
   background:transparent;
   border:3px solid #000;
    `}

    

`;

function Button(props) {
  

  return (<StyledButton {... props}/>);
}

export default Button;