
import MenuIcon from '../icons/menu_icon.svg';
import LocationIcon from '../icons/location_icon.svg';
//import BlueLocator from '../icons/blue_locator.svg';
import BlueLocator from '../icons/blue_locator.png';
import CalendarIcon from '../icons/calendar_icon.svg';
import rightChevron from '../icons/right-chevron.svg';
// import awardIcon from '../icons/right-chevron.svg';
// import infoIcon from '../icons/right-chevron.svg';
import CertificatesIcon from '../icons/certificates.svg';
import RatingIcon from '../icons/rating.svg';
import Description from '../icons/description.svg';
import Skills from '../icons/skills.svg';
import Wayfinder from '../icons/wayfinder.svg';
import CarpenterIcon from '../icons/carpenter.svg';
import MasonIcon from '../icons/mason.svg';
import ElectricianIcon from '../icons/electrician.svg';
import PlumberIcon from '../icons/plumber.svg';
import PainterIcon from '../icons/painter.svg';
import BoilmakerIcon from '../icons/boilmaker.svg';
import FencebuilderIcon from '../icons/fence_builder.svg';
import FloorLayerIcon from '../icons/floor_layer.svg';
import StoneMasonIcon from '../icons/mason.svg';
import SteelworkerIcon from '../icons/iron_steel_worker.svg';
import PipefitterIcon from '../icons/septic_sewag.svg';
import CraneoperatorIcon from '../icons/septic_sewag.svg';
import ConstructionWorker from '../icons/construction_worker.svg';
import TaperIcon from '../icons/taper.svg';
import DrillerIcon from '../icons/driller.svg';

const awardIcon = null;
const infoIcon = null;

const getIcon = title =>{

    if(title === "menu"){
        return MenuIcon;
    }

    return title == "Carpenter" ? CarpenterIcon : title == "Electrician" ? ElectricianIcon : title == "Mason" ? MasonIcon : title == "Stone Mason" ? MasonIcon : title == "Plumber" ? PlumberIcon : title == "Painter" ? PainterIcon : title == "Pipefitter" ? PipefitterIcon : title == "Steelworker" ? SteelworkerIcon : title == "Crane Operator" ? CraneoperatorIcon : title == "Boilmaker" ? BoilmakerIcon : title == "Driller" ? DrillerIcon : title == "Taper" ? TaperIcon : ConstructionWorker;
}

export {
    BlueLocator,
    LocationIcon,
    CalendarIcon,
    CarpenterIcon,
    CertificatesIcon,
    Skills,
    RatingIcon,
    awardIcon,
    Description,
    infoIcon,
    rightChevron,
    Wayfinder,
    getIcon
}
