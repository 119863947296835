import './App.css';
import { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {Input} from '@rebass/forms';

//Components
import {Box, Flex} from 'rebass';
import Button from './components/Button';
import Heading from './components/Heading';
import { getIcon } from './components/Icons';

//Resources
import appPreviewLeft from './images/app-preview-left.png';
import appPreviewRight from './images/app-preview-right.png';
import logo from './images/logo.png';
import hhIcon from './images/hh_icon.png';
import constructionBuilding from './images/construction_building.png';
import workersBackground from './images/workers_beam_background.png';
import workersOverlay from './images/workers_beam_color.png';
import workersCloseup from './images/workers_closeup.png';
import workerWelder from './images/worker_welder.png';
import contractors from './images/contractors.png';
import elitePreview from './images/elite_preview.png';
import eliteBadge from './images/badge_shield.png';

const theme = {
  yellowGradient: "linear-gradient(127deg, rgb(255, 238, 0) 12%, rgb(246, 191, 0) 76%)",
  generalPadding: "16px",
  darkYellow: "#f6bf00",
  lightYellow: "#ffc600",
  lighterYellow:"#ffef00"
}


const GlobalStyle = createGlobalStyle`
  body{background:#000; margin: 0;}

  #app-preview-section{padding: 0; position:relative; margin-top:100px;}
  #app-preview-section .worker-closeup{position: absolute; width: calc(380% + 32px); mix-blend-mode: luminosity; filter:brightness(1.2); left: calc(50% + 100px); z-index: 0; transform: translate(-50%,0); top: -240px;}
  #app-preview-section .trade-icons{ margin:50px 0;}

  .feature{margin: 0 0 50px !important; max-width:200px}
  .feature h1{font-size:24px; padding:0 0 16px;}
  .feature .gray-subtitle{font-size:14px;}

  #app-preview-container{margin:50px 0;}

  #elite-description{flex-direction:column; margin: 160px 0 0;}
  #elite-description .gray-subtitle{padding: 30px 0 0 30px; font-size: 14px;}

  .elite-preview-container{ position: relative; width: 100%; mix-blend-mode: none; left: -16px; z-index: 2; top: 120px; max-width:1080px;}

  img.elite-phone-preview{width:150%;}

  .elite-badge-container{left:44% !important; top:0px !important;}
  .elite-badge-container .yellow-circle{ width:80px; height: 80px; }
  .elite-badge-container .yellow-line{ left:80px; width: 90px; }
  .elite-badge-container .elite-badge{ width:100px; height: 120px; left:120px; padding: 35px 28px 40px; top: 20px;}

  .elite-yellow-box{ width: calc(100% + 50px) !important; height: 320px !important; transform: skew(-32deg) translate(6%,0) !important; left: 68px !important; bottom: 80px !important; }

  #pro-benefits{padding: 0 16px; min-height: 900px; position:relative;}
  
  #pro-benefits img{bottom: 0; top: unset;   position: absolute; width: 800px; mix-blend-mode: luminosity; right: -16px; z-index: 0; max-width: 580px; transform:translateX(30%);}

  #contractor-benefits{position:relative; min-height: 900px; display: flex; flex-direction:column; align-items:flex-end; z-index:1; padding: 0 16px;}

  #contractor-benefits img{bottom: 0; max-width: calc(100% + 80px);}

@media screen and (min-width:550px){
#app-preview-section{ text-align:center; font-size: 48px; padding: 100px 0 0 !important; min-height:550px; background:none; }
#app-preview-section .worker-closeup{width: 100%; left: -16px; top:0; transform: scale(1.5) translate(10%, 0);}
#app-preview-section .big-title{ text-align:center; font-size: 48px;}
#app-preview-section .gray-subtitle{ text-align:center; margin:0 auto; max-width:650px; font-size:21px;}
#app-preview-section .trade-icons{ margin:50px 0;}
#app-preview-section .trade-icons img{ margin:21px;}

.feature{margin: 0 0 100px !important;}
.feature h1{font-size:24px; padding:0 0 16px;}
.feature .gray-subtitle{font-size:14px;}

#elite-description{flex-direction:row; margin: 0 auto 80px; max-width:850px; width:fit-content;}
#elite-description .gray-subtitle{max-width: 480px; font-size: 14px; padding: 0 0 0 100px;}

.elite-preview-container{ position: absolute; width: 100%; mix-blend-mode: none; left: 50px; z-index: 2; top:160px;left:50%; transform:translateX(-50%);}

img.elite-phone-preview{width:78%; max-width:700px}

.elite-badge-container{left:21% !important; top:25px !important;}
.elite-badge-container .yellow-circle{ width:100px; height: 100px; }
.elite-badge-container .yellow-line{ left:100px; width: 390px; }
.elite-badge-container .elite-badge{ width:240px; height: 240px; left:400px; padding: 55px 55px 65px; top: 50px;}

.elite-yellow-box{ width: calc(100% + 50px) !important; height: 400px !important; transform: skew(-32deg) translate(6%,0) !important; left: 40px !important; bottom: 80px !important; }

#pro-benefits{padding: 0 32px; min-height:750px;}
#pro-benefits img{bottom: unset; top: -100px; max-width: 100%; transform: none;}
#contractor-benefits{padding: 0 32px; min-height: 750px;}
#contractor-benefits img{bottom: 0; max-width: calc(100% + 32px);}

#sign-up .contact-form{max-width:600px;}
#sign-up .submit-button{font-size:18px; padding: 0 48px; width:fit-content; min-width:fit-content;}
}

`;

const Menu = styled(Flex)`
  position:absolute;
  top:50px;
  left:0;
  width:100%;
  padding: 16px;
  background:linear-gradient(to right, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 40%);
  z-index:3;
  left:50%;
  transform: translateX(-50%);
  max-width: 1110px;
  @media screen and (min-width:550px){
    top:0;
    padding: 16px 32px;
    }
`;

const Logo = styled.div.attrs({className:"logo"})`
  background: url('${hhIcon}') no-repeat center/contain;
  height:100px;
`;

const HorizontalLogo = styled(Flex)`
  background: url('${logo}') no-repeat;
  width:100%;
    height: 45px;
    color: #fff;
    background-size: contain;
    margin: 0 0 0 16px;
    align-items: center;

    @media screen and (min-width:550px){
      width:160px;
      height: 35px;
    }
`;
const YellowInput = styled(Input)`
  border:2px solid ${theme.lightYellow} !important;
  color:#fff !important;
  height:50px;
  padding:12px !important;
  font-size:18px !important;
  margin:0 0 16px !important;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
}
`;

const BigTitle = styled.div.attrs({className:"big-title"})`
  font-size:32px;
  font-weight:700;
  color:#fff;
  margin:24px 0;
`;
const Homepage = styled.div`
font-family: 'Roboto', sans-serif;
background:black;
position:relative;
width:100%;
max-width:1440px;
margin:0 auto;
height:100%;
top:0;
left:0;
overflow-x:hidden;
overflow-y:auto;
padding: 0 16px;
box-sizing:border-box;
`;

const Container = styled.div`margin:0 auto; width:100%; max-width:1080px;`;

const JobIconList = styled(Box)`
  text-align:center;
  margin:16px 0 0;
  & img{}
`;

const Icon = styled.img`
  width:64px;
  margin:8px;
`;


const GraySubtitle = styled(Box).attrs({className:"gray-subtitle"})`
&{
color:#7c7c7c;
margin: 16px 0;}
`;


const BenefitsTitle = styled(Flex)`
width: fit-content;
border:10px solid ${theme.lightYellow};
color:#fff;
font-size:32px;
font-weight:900;
text-align:center;
align-items:center;
padding:16px 32px;
justify-content:center;
min-height:160px;
margin:48px 0 !important;
`;

const BenefitsList = styled(Box)`
  color:#7c7c7c;
  margin:16px 0;
  text-indent: -28px;
  padding-left: 28px;
  & div{margin:12px 0;}
  & div::before{display:inline-block; content:" "; width:16px; height:1px; background: ${theme.lightYellow}; vertical-align:middle; margin:0 12px 0 0;}
`;


const WorkersHero = styled(Box)`
position:absolute;
top:0;
right:0;
width:300px;
height: auto;
padding: 100% 0 0;
z-index: 0;

 & .workers-background{
    width:180%;
    position: absolute;
    top: 0;
    right: -72%;
    }

  & .workers-overlay{
    width: 142%;
    position: absolute;
    top: 0;
    right: -72%;
    z-index: 2;
    padding: 0;
  }

  & #yellow-box{left: unset; width: 35px; right: 0px;}


  @media screen and (min-width:550px){
   width: 640px;
   height: 100%;

   & #yellow-box{left: unset; width: 44%; right: 50px;}
   & .workers-background{width:127%; right: 0;}
   & .workers-overlay{width: 100%; right: 0;}
  }
`;


const Features = styled(Box)`
 position: relative;

 & #app-preview-container{
   position: relative;
   min-height:500px;
 }

 & #features-left{position: relative;
    left: 0;
    top: 0px;
    padding: 16px;
    box-sizing: border-box;
    width: 300px;}

  & #features-right{position: relative;
    right: 0;
    
    padding: 16px;
    box-sizing: border-box;
    width: 300px;
    z-index: 2;

    }

 & #app-preview-left{
    position: absolute; 
    width: calc(50%); 
    mix-blend-mode: none; 
    left: 0;
    top: 0;
    z-index: 2;
    transform: none;
    } 

  & #app-preview-right{
    position: absolute;
    width: calc(50%);
    mix-blend-mode: none;
    right: 0;
    top: 0;
    z-index: 1;
    transform: translate(0px, -13px) scale(0.97);
 
    }


@media screen and (min-width:550px){
   min-height: 850px;

   & #app-preview-container{
   position: static;
   min-height:unset;
  }

 


   & #features-left{position: absolute;
    left: 0;
    top: 0px;
    padding: 16px;
    box-sizing: border-box;
    width: 300px;}

  & #features-right{position: absolute;
    right: -24px;
    top: 100px;
    padding: 16px;
    box-sizing: border-box;
    width: 300px;
    z-index: 2;

    }

  & #app-preview-left{
    width: calc(250px); 
    mix-blend-mode: none; 
    left: calc(50%);
    transform: translate(-100%);
    top: 0;
    z-index: 2;
    } 

  & #app-preview-right{
    width: calc(235px);
    mix-blend-mode: none;
    right: calc(50% + 10px);
    transform: translate(100%, 0);
    top: 50px;
    z-index: 1;
    }
  }


`;

const YellowBox = styled(Box)`
position: absolute;
width: 44%;
background: linear-gradient(to bottom, ${theme.lighterYellow}, ${theme.darkYellow});
height: calc(100%);
right: 100px;
z-index: 1;
`;
const YellowLine = styled(Box)`
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, transparent, #ffef00, transparent);
`;

const EliteBadge = styled(Box).attrs({className:"elite-badge"})`
position:absolute;
z-index:2;
left:320px;
  width:250px;
  height:250px;
  background:url('${eliteBadge}') no-repeat center/contain;
  display:flex;
  justify-content:center;
  align-items:center;
  top: 50%;
  padding:55px 65px 65px;
  box-sizing: border-box;
 
  transform: translateY(-50%);
  & .logo{width:100%}
`;
const Lines = styled(Box)`
  z-index: 2;
    position: absolute;
    left: 21%;
    top: 32px;
}
`;
const Circle = styled(Box).attrs({className:"yellow-circle"})`border:1px solid ${theme.lighterYellow}; width:100px; height:100px; border-radius:50%;`;

const Line = styled(Box).attrs({className:"yellow-line"})`
  height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    background: ${theme.lighterYellow};
    width: 300px;
    transform-origin:left center;
`;


const SignUp = styled(Box)`
  position: relative;
  padding: 200px 0 100px;

  & .construction-building{
    position: absolute; 
    object-fit:cover;
    width: calc(100% + 32px);
    bottom:0; 
    mix-blend-mode:luminosity; 
    left:-16px;
    height: 100%;
  }

  & form{flex-direction:column;}
  & form input{text-align:center;}
  & form button{margin: 0; box-shadow: none; position: relative; margin:0 0 24px 0;}

@media screen and (min-width:550px){
  & .construction-building{
    position: absolute;
    width: 100%;
    bottom:0;
    mixBlendMode:luminosity;
    left:-16px;
  }

  & form{flex-direction:row}
  & form input{text-align:left;}
  & form button{\ margin:0 0 24px 16px;}
 
}
`


const Intro = styled(Box)`
 padding: 250px 16px 0 0;
 overflow:hidden;
 position:relative;
 width: calc(100% + 16px);

 & .big-title{font-size: 36px; z-index: 1; position:relative;}
 & button{width:100%; color: #fff; border-color:${theme.lighterYellow}; margin: 0 0 24px; padding: 0 65px; border-width: 2px; font-size: 18px;}


 & #yellow-box{top:0;}

 

 @media screen and (min-width:550px){
  padding:100px 16px 0;
  width:calc(100% + 32px);

  & .big-title{font-size:42px; max-width:450px;}
  & button{width:fit-content; font-size: 14px;}
 }
`;


function App() {

  useEffect(()=>{
    // window.timeline = window.gsap.timeline();
    // window.timeline.from(".workers-background",{opacity:0, x:"100px"});
    // window.timeline.from(".workers-overlay",{opacity:0});
    // window.timeline.from("#yellow-box",{opacity:0, height:0});
    // window.timeline.from("#app-preview-left",{opacity:0, y:"100px"});
    // window.timeline.from("#app-preview-right",{opacity:0, y:"100px"});
    // window.timeline.from(".feature",{opacity:0});
    // window.timeline.from(".elite-phone-preview",{opacity:0, x:"-100px"});
    // window.timeline.from(".elite-yellow-box",{opacity:0, x:"100px"});
    // window.timeline.from(".yellow-circle",{opacity:0, scale:"0", duration:0.25});
    // window.timeline.from(".yellow-line",{opacity:0, scaleX:"0", duration:0.25});
    // window.timeline.from(".elite-badge",{opacity:0, scale:"0", duration:0.25});
  },[]);

  return (
    <Homepage>
    <GlobalStyle/>
    <Menu><img src={getIcon("menu")} style={{filter:"invert(1) brightness(0.5)", display:"none"}}/> <HorizontalLogo/></Menu>
  
  <Intro>
  <Container>
  <WorkersHero id="workers-hero">
  <img src={workersBackground} className="workers-background"/>
  <img src={workersOverlay} className="workers-overlay"/>
  <YellowBox id="yellow-box"/>

  </WorkersHero>
  <BigTitle>Instantly connecting contractors with trusted pros</BigTitle>

  <Box style={{borderLeft:`4px solid ${theme.lighterYellow}`,color:"#fff", padding:"0 0 0 24px", margin:"36px 0", fontSize:"14px", lineHeight:"1"}}>
    <GraySubtitle style={{margin:"8px 0"}}> Find jobs or hire <span style={{fontWeight:"900"}}>instantly</span></GraySubtitle>
    <GraySubtitle style={{margin:"8px 0"}}><span style={{fontWeight:"900"}}>Network</span> with other professionals</GraySubtitle>
    <GraySubtitle style={{margin:"8px 0"}}>Build your <span style={{fontWeight:"900"}}>digital resume</span></GraySubtitle>
  </Box>
    <Button style={{position:"relative", zIndex:1}} knockedOut onClick={(e)=>{
      document.querySelector("#MERGE0").scrollIntoView({behavior: "smooth"});
      document.querySelector("#MERGE0").focus();
    }}>STAY UPDATED</Button>
   </Container>
  </Intro>
  <YellowLine/>

  <Box id="app-preview-section">
  <Container>
  <img className="worker-closeup" src={workersCloseup} />
    <BigTitle>Hire qualified pros in seconds</BigTitle>
    <GraySubtitle>Connect to professional Tradesmen, Carpenters,
Plumbers, Electricians, Masons, Commercial Drivers
and more</GraySubtitle>

    <JobIconList className="trade-icons">
  <Icon src={getIcon("Carpenter")}/>
  <Icon src={getIcon("none")}/>
  <Icon src={getIcon("Electrician")}/>
  <Icon src={getIcon("Mason")}/>
  <Icon src={getIcon("Pipefitter")}/>
  <Icon src={getIcon("Painter")}/>
  </JobIconList>
  
  

</Container>
  </Box>

  

<Features>
<Container style={{position:"relative"}}>
<Box id="features-left">
  <Box className="feature">
    <Heading style={{color:"#fff"}}>Find Jobs</Heading>
    <GraySubtitle>Real-time access to jobs and 
flexibility around your schedule.</GraySubtitle>
  </Box>
    <Box className="feature">
  <Heading style={{color:"#fff"}}>Always on</Heading>
    <GraySubtitle>Instant, free access to jobs 24/7.</GraySubtitle>
    </Box>
  </Box>

  
   
    <Box id="app-preview-container">
    <img id="app-preview-left" src={appPreviewLeft}/>
    <img id="app-preview-right" src={appPreviewRight}/>
    </Box>

    <Box id="features-right">
  <Box className="feature">
    <Heading style={{color:"#fff"}}>Find Pros</Heading>
    <GraySubtitle>Immediately find and hire qualified workers in all trades.</GraySubtitle>
  </Box>

  <Box className="feature">
    <Heading style={{color:"#fff"}}>On-demand<br/>Network</Heading>
    <GraySubtitle>Build a trusted network and relationships.</GraySubtitle>
  </Box>
</Box>
    </Container>
  </Features>

  <Box style={{position:"relative", minHeight:"800px", zIndex: 1}}>
<Container>
  <Flex id="elite-description">
  <BigTitle style={{borderLeft:`4px solid ${theme.lighterYellow}`, paddingLeft:"24px", fontSize:"36px", margin:"0", alignSelf:"flex-start"}}>Verified pros<br/> benefit everyone</BigTitle>
    <GraySubtitle>We us ID verification for elite staus pros to 
instantly vet personal information, plus a rating 
system to provide complete security and quality.
Competitors don’t.
	<br/><br/>
Verified pros gain elite benefits of unlimited job 
applications, preferred search placement and 
profile assistance.
	</GraySubtitle>
  </Flex>

  <Box position="relative"  className="elite-preview-container">
  <img src={elitePreview} className="elite-phone-preview"/>
  <Lines className="elite-badge-container">
    <Circle/>
    <Line/>
    <EliteBadge>
      <Logo/>
    </EliteBadge>
  </Lines>
  
  </Box>
  <YellowBox className="elite-yellow-box"/>
  </Container>
  </Box>


  <Box id="pro-benefits">
  <Container>
    <BenefitsTitle  style={{position:"relative", zIndex:"2"}}>Benefits for Pros</BenefitsTitle>
    <BenefitsList style={{position:"relative", zIndex:"1"}}>
      <Box>Always on. Instant, free access to jobs 24/7</Box>
      <Box>Flexibility around your schedule  </Box>
      <Box>Find jobs by location and pay rate</Box>
      <Box>Build reputation, boost credentials</Box>
      <Box>Share your work</Box>
    </BenefitsList>
    <img src={workerWelder}/>
    </Container>
  </Box>

  <Box id="contractor-benefits">
  <Container style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
  <img src={contractors}  style={{position: "absolute", width: "800px", mixBlendMode:"luminosity", bottom:"0", left:"-16px", zIndex:"0"}}/>
    <BenefitsTitle  style={{position:"relative", zIndex:"2"}}>Benefits for Contractors</BenefitsTitle>
    <BenefitsList style={{position:"relative", zIndex:"1", maxWidth:"400px"}}>
      <Box>Immediately find and hire qualified workers in all trades</Box>
      <Box>Secure ID of personal credentials with elite status</Box>
      <Box>Save time and money</Box>
      <Box>Transparent view of a richer, more diverse pool of tradespeople</Box>
      <Box>Build trusted network and relationships</Box>
    </BenefitsList>
    </Container>
  </Box>


  {/* <Box style={{background:`url('${constructionBuilding}') no-repeat bottom / cover`}}> */}
  <SignUp id="sign-up">
  <img src={constructionBuilding}  className="construction-building"/>
  <Container>

  <Logo style={{margin:"0 0 24px 0", position:"relative"}} sx="margin:0 0 24px 0;"/>
  
  <BigTitle style={{fontSize:"34px", textAlign:"center", margin:"0 0 100px", position:"relative"}}>Construction hiring just got easier</BigTitle>
  <Flex maxWidth="800px" margin="0 auto" position="relative" className="contact-form" position="relative" style={{zIndex:"2"}}> 

  <form action="https://hiringhall.us1.list-manage.com/subscribe/post?u=bb1932b76490befe106ab2373&amp;id=9e0f148025" method="POST" _lpchecked="1" style={{width:"100%", display:"flex", position:'relative', zIndex:2}}> 
  

    <YellowInput placeholder="ENTER YOUR EMAIL" className="email-field" type="email" autocapitalize="off" autocorrect="off" name="MERGE0" id="MERGE0" size="25"/>
    <Button primary className="submit-button" action={e=>{
      e.currentTarget.parentNode.submit();
    }}>STAY UPDATED</Button>
    </form>
    </Flex>
    </Container>
  </SignUp>

  
    </Homepage>
    
    );
}

export default App;
